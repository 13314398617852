.categories-row {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 20px;
  border: 1px solid #ccc; /* Rectangle border */
  padding: 10px;
  border-radius: 5px; /* Optional: rounded corners */
  background-color: #f9f9f9; /* Optional: background color */
}

.category-select,
.subcategory-select {
  grid-column: span 3; /* Reduce size */
}

.filters-select {
  grid-column: span 6; /* Increase size */
}

.category-select label,
.subcategory-select label,
.filters-select label {
  display: block;
  margin-bottom: 5px;
}

.category-select select,
.subcategory-select select,
.filters-select select {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}