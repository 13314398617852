@keyframes shakeIn {
    from,
    to {
        transform: translate3d(0, 0, 0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translate3d(-10px, 0, 0);
    }

    20%,
    40%,
    60%,
    80% {
        transform: translate3d(10px, 0, 0);
    }
}

@keyframes deleteItem {
    20% {
        opacity: 1;
        transform: translate3d(20px, 0, 0);
    }

    80% {
        opacity: 1;
        transform: translate3d(-150%, 0, 0);
        max-height: 200px;
        overflow: hidden;
    }

    to {
        opacity: 0;
        transform: translate3d(-150%, 0, 0);
        max-height: 0px;
        overflow: hidden;
    }
}
