.TagComponent .closed {
  display: none;
}

.TagComponent {
  padding-left: 5rem;
  margin: 0 auto;
}

.TagComponent .header {
  display: flex;
  width: 60rem;
  justify-content: space-around;
  margin: 2rem 0;
}

.TagComponent .expand-setting {
  width: 1rem;
}
.TagComponent .clickable {
  cursor: pointer;
}
.TagComponent .title,
.TagComponent .create-name {
  width: 20rem;
}
.TagComponent .create,
.TagComponent .save-new-name {
  width: 21rem;
}
.TagComponent .save {
  width: 5rem;
  margin-left: 1rem;
}
.TagComponent .edit-name {
  width: 15rem;
}
.TagComponent .create-header {
  box-sizing: content-box;
  padding-left: 5rem;
}
